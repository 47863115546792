import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from '../../../../../service/axios';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Loader Components
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getElectionResult = createAsyncThunk('electionResultSlice/getElectionResult', async () => {
    try {
        let response = await axios.get('/election-result', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createElectionResult = createAsyncThunk('electionResultSlice/createElectionResult', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/election-result', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || ' Election Result added successfully.');
        dispatch(getElectionResult());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateElectionResult = createAsyncThunk('electionResultSlice/updateElectionResult', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/election-result/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Election Resultupdated successfully.');
        dispatch(getElectionResult());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteElectionResult = createAsyncThunk('electionResultSlice/deleteElectionResult', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/election-result/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Election Result deleted successfully.');
        await dispatch(getElectionResult());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('electionResultSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/election-result/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(getElectionResult());
        toast.success(response?.data?.message || 'Election Result status updated successfully !');
        showLoader(false);
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data.message || 'Something went wrong!');
        showLoader(false);
        return Promise.reject(err);
    }
});

// export const getTalukaOptions = createAsyncThunk('talukaSlice/getTalukaOptions', async (data) => {
//     try {
//         const query = queryString.stringify(data);
//         let response = await axios.get('/taluka/options' +`?${query}`,{
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         return Promise.resolve(response.data.data);
//     } catch (err) {
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });

export const electionResultSlice = createSlice({
    name: 'electionResultSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getElectionResult.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        // builder.addCase(getTalukaOptions.fulfilled, (state, action) => {
        //     state.options = action?.payload;
        //     return;
        // });
    }
});

export default electionResultSlice.reducer;
