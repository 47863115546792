import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getCasteReport = createAsyncThunk('reportSlice/getCasteReport', async (data) => {
    try {
        // const query = queryString.stringify(data);

        let response = await axios.post('/report/caste', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getElectionResultReport = createAsyncThunk('reportSlice/getElectionResultReport', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/report/election-result' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVotingResultReport = createAsyncThunk('reportSlice/getVotingResultReport', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/report/voting-result' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVotingResultReport7 = createAsyncThunk('reportSlice/getVotingResultReport7', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/report/vote-cast-status' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getZpWiseReport = createAsyncThunk('reportSlice/getZpWiseReport', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/report/zp-wise' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAllConstituencyCount = createAsyncThunk('reportSlice/getAllConstituencyCount', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.post('/report/constituency', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getDetailedReport = createAsyncThunk('reportSlice/getDetailedReport', async (data) => {
    try {
        let response = await axios.post('/report/inner-view-details', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDetailedDifferenceReport = createAsyncThunk('reportSlice/getDetailedDifferenceReport', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/report/difference-report' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getConstituencyDetailsReport = createAsyncThunk('reportSlice/getConstituencyDetailsReport', async (data) => {
    try {
        let response = await axios.post('/report/constituency/details/' + data?.constituencyType, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFinalSurveyCounts = createAsyncThunk('reportSlice/getFinalSurveyCounts', async (data) => {
    try {
        let response = await axios.post('/report/final-prediction/counts', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFinalSurveyReport = createAsyncThunk('reportSlice/getFinalSurveyReport', async (data) => {
    try {
        let response = await axios.post('/report/final-prediction', data.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const reportSlice = createSlice({
    name: 'reportSlice',
    initialState: {
        casteReport: [],
        electionResultReport: [],
        votingResultReport: [],
        votingResultReportIndexed: {},
        votingResultReport7: [],
        zpWiseReport: [],
        constituency: {
            data: [],
            Elections: {}
        },
        detailedReport: [],
        differenceDetailReport: [],
        finalSurvey: {
            data: [],
            count: {
                greenCount: 0,
                redCount: 0,
                yellowCount: 0,
                greyCount: 0,
                whiteCount: 0
            }
        }
    },
    reducers: {
        resetVotingResultReport: (state) => {
            state.votingResultReport = []; // Reset the report data to an empty array
            state.votingResultReportIndexed = {};
        },
        resetVotingResultReport7: (state) => {
            state.votingResultReport7 = []; // Reset the report data to an empty array
        },
        updateVotingReport: (state, action) => {
            state.votingResultReportIndexed[action.payload.id] = action.payload;
            state.votingResultReport = Object.values(state.votingResultReportIndexed);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCasteReport.fulfilled, (state, action) => {
            state.casteReport = action?.payload;
            return;
        });
        builder.addCase(getElectionResultReport.fulfilled, (state, action) => {
            state.electionResultReport = action?.payload;
            return;
        });
        builder.addCase(getVotingResultReport.fulfilled, (state, action) => {
            state.votingResultReport = action?.payload;
            const indexedData = {};
            action?.payload.forEach((row) => {
                indexedData[row.id] = row;
            });
            state.votingResultReportIndexed = indexedData;
            return;
        });
        builder.addCase(getZpWiseReport.fulfilled, (state, action) => {
            state.zpWiseReport = action?.payload;
            return;
        });
        builder.addCase(getVotingResultReport7.fulfilled, (state, action) => {
            state.votingResultReport7 = action?.payload;
            return;
        });
        builder.addCase(getAllConstituencyCount.fulfilled, (state, action) => {
            state.constituency = action?.payload;
            return;
        });
        builder.addCase(getDetailedReport.fulfilled, (state, action) => {
            state.detailedReport = action?.payload;
            return;
        });
        builder.addCase(getDetailedDifferenceReport.fulfilled, (state, action) => {
            state.differenceDetailReport = action?.payload;
            return;
        });
        builder.addCase(getFinalSurveyReport.fulfilled, (state, action) => {
            state.finalSurvey.data = action?.payload;
            return;
        });
        builder.addCase(getFinalSurveyCounts.fulfilled, (state, action) => {
            state.finalSurvey.count = action?.payload;
            return;
        });
    }
});

export const { resetVotingResultReport, resetVotingResultReport7, updateVotingReport } = reportSlice.actions;

export default reportSlice.reducer;
