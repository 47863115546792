// theme config import
import themeConfig from '@configs/themeConfig';

const SpinnerComponent = () => {
    return (
        <div className="fallback-spinner app-loader">
            {themeConfig?.app?.appLoader === undefined ||
            themeConfig?.app?.appLoader === null ||
            themeConfig?.app?.appLoader === '' ? null : (
                <img className="fallback-logo" src={themeConfig?.app?.appLoader} alt="logo" width="auto" height="60px" />
            )}
            <div className="loading">
                <div className="effect-1 effects"></div>
                <div className="effect-2 effects"></div>
                <div className="effect-3 effects"></div>
            </div>
        </div>
    );
};

export default SpinnerComponent;
