var service = {
    mapLibraries: ['places'],
    REACT_APP_GOOGLE_MAP_KEY: 'AIzaSyB_0OwcvvHVGQGPT5FA8Hl3LpYBxC1vvmA'
};
if (process.env.NODE_ENV === 'production') {
    service.API_URL = 'https://prod-evoter-api.mksdigitech.com';
} else {
    service.API_URL = 'http://192.168.1.148:5001';
}

export default service;
