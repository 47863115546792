import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
// import loader from 'sass-loader';

export const getAppBanner = createAsyncThunk('appBannerSlice/getAppBanner', async () => {
    try {
        let response = await axios.get('/app-banner', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAppBanner = createAsyncThunk('appBannerSlice/createAppBanner', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/app-banner', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'App Banner created successfully.');
        dispatch(getAppBanner());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});
export const deleteAppBanner = createAsyncThunk('appBannerSlice/deleteAppBanner', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/app-banner/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'App Banner deleted successfully');
        dispatch(getAppBanner());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAppBanner = createAsyncThunk('appBannerSlice/updateAppBanner', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/app-banner/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'App Banner updated successfully.');
        dispatch(getAppBanner());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateStatus = createAsyncThunk('appBannerSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/app-banner/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getAppBanner());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const appBannerSlice = createSlice({
    name: 'appBannerSlice',
    initialState: {
        data: {}
    },

    extraReducers: (builder) => {
        builder.addCase(getAppBanner.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default appBannerSlice.reducer;
