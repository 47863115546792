import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getQSurveyReportOne = createAsyncThunk('qSurveyReportSlice/getQSurveyReportOne', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/q-survey-result/report' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getQSurveyReportTwo = createAsyncThunk('qSurveyReportSlice/getQSurveyReportTwo', async (data) => {
    try {
        let response = await axios.post('/q-survey-result/voter/report', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const qSurveyReportSlice = createSlice({
    name: 'qSurveyReportSlice',
    initialState: {
        qSurvey: []
    },
    extraReducers: (builder) => {
        builder.addCase(getQSurveyReportOne.fulfilled, (state, action) => {
            state.qSurvey = action?.payload;
            return;
        });
    }
});

export default qSurveyReportSlice.reducer;
