import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
export const getZillaParisad = createAsyncThunk('zillaParisadSlice/getZillaParisad', async () => {
    try {
        let response = await axios.get('/zilla-parisad', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createZillaParisad = createAsyncThunk('zillaParisadSlice/createZillaParisad', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/zilla-parisad', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Zilla Parishad added successfully.');
        dispatch(getZillaParisad());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateZillaParisad = createAsyncThunk('zillaParisadSlice/updateZillaParisad', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/zilla-parisad/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Zilla Parishad updated successfully.');
        dispatch(getZillaParisad());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteZillaParisad = createAsyncThunk('zillaParisadSlice/deleteZillaParisad', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/zilla-parisad/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Zilla Parisad deleted successfully.');
        await dispatch(getZillaParisad());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('zillaParisadSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/zilla-parisad/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Zilla Parisad Status updated successfully !');
        dispatch(getZillaParisad());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getZillaParisadOptions = createAsyncThunk('zillaParisadSlice/getZillaParisadOptions', async (id) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/zilla-parisad/options' + `${id ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getZillaParishadById = createAsyncThunk('zillaParisadSlice/getZillaParishadById', async ({ id, isDashboard = false }) => {
    try {
        let response = await axios.get('/zilla-parisad/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: { isDashboard }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const zillaParisadSlice = createSlice({
    name: 'zillaParisadSlice',
    initialState: {
        data: [],
        lokSabhaOptions: [],
        vidhanSabhaOptions: []
    },

    extraReducers: (builder) => {
        builder.addCase(getZillaParisad.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getZillaParisadOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default zillaParisadSlice.reducer;
