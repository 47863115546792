import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getYear = createAsyncThunk('yearSlice/getCity', async () => {
    try {
        let response = await axios.get('/year', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createYear = createAsyncThunk('yearSlice/createYear', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/year', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Year added successfully.');
        dispatch(getYear());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateYear = createAsyncThunk('yearSlice/updateYear', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/year/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Year updated successfully.');
        dispatch(getYear());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteYear = createAsyncThunk('yearSlice/deleteYear', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/year/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Year deleted successfully.');
        await dispatch(getYear());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('yearSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/year/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getYear());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const yearSlice = createSlice({
    name: 'yearSlice',
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getYear.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default yearSlice.reducer;
