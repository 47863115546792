import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getElectionParty = createAsyncThunk('electionPartySlice/getElectionParty', async () => {
    try {
        let response = await axios.get('/election-party', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createElectionParty = createAsyncThunk(
    'electionPartySlice/createElectionParty',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/election-party', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Election Party added successfully.');
            dispatch(getElectionParty());
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const updateElectionParty = createAsyncThunk(
    'electionPartySlice/updateElectionParty',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/election-party/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Election Party updated successfully.');
            dispatch(getElectionParty());
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const deleteElectionParty = createAsyncThunk('electionPartySlice/deleteElectionParty', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/election-party/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Election Party deleted successfully.');
        await dispatch(getElectionParty());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('electionPartySlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/election-party/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Election Party Status updated successfully !');
        dispatch(getElectionParty());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getElectionPartyOptions = createAsyncThunk('electionSlice/getElectionPartyOptions', async () => {
    try {
        let response = await axios.get('/election-party/options', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const electionPartySlice = createSlice({
    name: 'electionPartySlice',
    initialState: {
        data: [],
        electionPartyOptions: []
    },

    extraReducers: (builder) => {
        builder.addCase(getElectionParty.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getElectionPartyOptions.fulfilled, (state, action) => {
            state.electionPartyOptions = action?.payload;
            return;
        });
    }
});

export default electionPartySlice.reducer;
