import React, { createContext, useState, useEffect, useContext } from 'react';
import { io } from 'socket.io-client';
import service from '@src/service/constant';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserStatus } from '../../redux/authentication';
// import { getUserToken } from '@utils';

const SOCKET_SERVER_URL = service.API_URL;

const SocketContext = createContext(null);
const debugMode = String(process.env.DEBUG_MODE) === 'true' || process.env.NODE_ENV === 'development' ? true : false;

export const SocketProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [socket, setSocket] = useState(null);
    const userToken = useSelector((state) => state.auth).accessToken;

    useEffect(() => {
        if (userToken) {
            const newSocket = io(SOCKET_SERVER_URL, {
                transports: ['websocket'],
                auth: { token: userToken }
            });

            setSocket(newSocket);

            newSocket.on('connect', () => {
                if (debugMode) console.log('Socket Connected:', newSocket.id);
                dispatch(changeUserStatus(true));
            });

            newSocket.on('disconnect', () => {
                if (debugMode) console.log('Socket Disconnected');
                dispatch(changeUserStatus(false));
            });

            return () => {
                newSocket.close();
                return true;
            };
        }
    }, [userToken]);

    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export const useSocket = () => {
    const socket = useContext(SocketContext);
    if (!socket) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return socket;
};
