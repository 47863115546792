import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components

// export const getManageInfluence = createAsyncThunk('manageInfluenceSlice/getManageInfluence', async (data) => {
//     try {
//         let response = await axios.get('/influencer/' + data, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         return Promise.resolve(response.data);
//     } catch (err) {
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });

export const createManageInfluence = createAsyncThunk('manageInfluenceSlice/createManageInfluence', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/influencer', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Influence added successfully.');
        dispatch(getManageInfluenceById(data?.voterId));
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateManageInfluence = createAsyncThunk('manageInfluenceSlice/updateManageInfluence', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/influencer/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Manage Influence updated successfully.');
        dispatch(getManageInfluenceById(data?.data?.voterId));
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteManageInfluence = createAsyncThunk('manageInfluenceSlice/deleteManageInfluence', async (data, { dispatch }) => {
    try {
        let response = await axios.delete('/influencer/' + data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Manage Influence deleted successfully.');
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getManageInfluenceById = createAsyncThunk('manageInfluenceSlice/getManageInfluenceById', async (id) => {
    try {
        let response = await axios.get('/influencer/voter/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const manageInfluenceSlice = createSlice({
    name: 'manageInfluenceSlice',
    initialState: {
        // data: [],
        manageInfluenceData: []
    },
    extraReducers: (builder) => {
        // builder.addCase(getManageInfluence.fulfilled, (state, action) => {
        //     state.data = action?.payload;
        //     return;
        // });
        builder.addCase(getManageInfluenceById.fulfilled, (state, action) => {
            state.manageInfluenceData = action?.payload;
            return;
        });
    }
});

export default manageInfluenceSlice.reducer;
