import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
export const getNagarParisad = createAsyncThunk('nagarParisadSlice/getNagarParisad', async () => {
    try {
        let response = await axios.get('/nagar-parishad', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createNagarParisad = createAsyncThunk('nagarParisadSlice/createNagarParisad', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/nagar-parishad', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Nagar Parishad added successfully.');
        dispatch(getNagarParisad());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateNagarParisad = createAsyncThunk('nagarParisadSlice/updateNagarParisad', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/nagar-parishad/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Nagar Parishad updated successfully.');
        dispatch(getNagarParisad());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteNagarParisad = createAsyncThunk('nagarParisadSlice/deleteNagarParisad', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/nagar-parishad/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Nagar Parishad deleted successfully.');
        dispatch(getNagarParisad());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('nagarParisadSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/nagar-parishad/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        await dispatch(getNagarParisad());
        toast.success(response?.data?.message || 'Nagar Parishad status updated successfully !');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getNagarParisadOptions = createAsyncThunk('nagarParisadSlice/getNagarParisadOptions', async (id) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/nagar-parishad/options' + `${id ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getNagarParishadById = createAsyncThunk('nagarParisadSlice/getNagarParishadById', async ({ id, isDashboard = false }) => {
    try {
        let response = await axios.get('/nagar-parishad/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: { isDashboard }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const nagarParisadSlice = createSlice({
    name: 'nagarParisadSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getNagarParisad.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getNagarParisadOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default nagarParisadSlice.reducer;
