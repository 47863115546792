import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getElectionType = createAsyncThunk('electionTypeSlice/getElectionType', async () => {
    try {
        let response = await axios.get('/election-type', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createElectionType = createAsyncThunk('electionTypeSlice/createElectionType', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/election-type', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Caste added successfully.');
        dispatch(getElectionType());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateElectionType = createAsyncThunk('electionTypeSlice/updateElectionType', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/election-type/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Caste updated successfully.');
        dispatch(getElectionType());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteElectionType = createAsyncThunk('electionTypeSlice/deleteElectionType', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/election-type/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Caste deleted successfully.');
        await dispatch(getElectionType());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('electionTypeSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/election-type/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Caste Status updated successfully !');
        dispatch(getElectionType());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getElectionTypeOptions = createAsyncThunk('electionTypeSlice/getElectionTypeOptions', async () => {
    try {
        let response = await axios.get('/election-type/options', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const electionTypeSlice = createSlice({
    name: 'electionTypeSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getElectionType.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getElectionTypeOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default electionTypeSlice.reducer;
