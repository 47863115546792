import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';

export const getAllVidhanSabha = createAsyncThunk('vidhanSabhaSlice/getVidhanSabha', async () => {
    try {
        let response = await axios.get('/vidhan-sabha', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createVidhanSabha = createAsyncThunk('vidhanSabhaSlice/createVidhanSabha', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/vidhan-sabha', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Vidhan Sabha added successfully.');
        dispatch(getAllVidhanSabha());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateVidhanSabha = createAsyncThunk('vidhanSabhaSlice/updateVidhanSabha', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/vidhan-sabha/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Vidhan Sabha updated successfully.');
        dispatch(getAllVidhanSabha());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteVidhanSabha = createAsyncThunk('vidhanSabhaSlice/deleteVidhanSabha', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/vidhan-sabha/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Vidhan Sabha deleted successfully.');
        await dispatch(getAllVidhanSabha());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('vidhanSabhaSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/vidhan-sabha/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Vidhan Sabha Status updated successfully !');
        dispatch(getAllVidhanSabha());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVidhanSabhaOptions = createAsyncThunk('vidhanSabhaSlice/getVidhanSabhaOptions', async (id) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/vidhan-sabha/options' + `${id ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVidhanSabhaById = createAsyncThunk('vidhanSabhaSlice/getVidhanSabhaById', async ({ id, isDashboard = false }) => {
    try {
        let response = await axios.get('/vidhan-sabha/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: { isDashboard }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const vidhanSabhaSlice = createSlice({
    name: 'vidhanSabhaSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getAllVidhanSabha.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getVidhanSabhaOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default vidhanSabhaSlice.reducer;
