import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getMenuOrder = createAsyncThunk('menuOrderSlice/getMenuOrder', async () => {
    try {
        let response = await axios.get('/all-menu', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const sortMenuOrder = createAsyncThunk('menuOrderSlice/sortMenuOrder', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/menu-order-level', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        dispatch(getMenuOrder());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateMenu = createAsyncThunk('menuOrderSlice/updateMenu', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/menu-order/change-label/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Material updated successfully.');
        dispatch(getMenuOrder());
        return Promise.resolve(response.data);
    } catch (err) {
        console.log('err', err);
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const updateStatus = createAsyncThunk('menuOrderSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/menu-order/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getMenuOrder());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getNavigationMenu = createAsyncThunk('menuOrderSlice/getNavigationMenu', async () => {
    try {
        let response = await axios.get('/menu', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const menuOrderSlice = createSlice({
    name: 'menuOrderSlice',
    initialState: {
        menuOrder: [],
        navigationMenu: []
    },

    extraReducers: (builder) => {
        builder.addCase(getMenuOrder.fulfilled, (state, action) => {
            state.menuOrder = action?.payload;
            return;
        });
        builder.addCase(getNavigationMenu.fulfilled, (state, action) => {
            state.navigationMenu = action?.payload;
            return;
        });
    }
});

export default menuOrderSlice.reducer;
