import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components

export const getUser = createAsyncThunk('userSlice/getUser', async () => {
    try {
        let response = await axios.get('/user', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createUser = createAsyncThunk('userSlice/createUser', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/user', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'User created successfully.');
        dispatch(getUser());

        return Promise.resolve(response.data.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        // return Promise.reject(err);
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const updateUser = createAsyncThunk('userSlice/updateUser', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/user/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'User updated successfully.');
        dispatch(getUser());

        return Promise.resolve(response.data.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        // return Promise.reject(err);

        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const getUserById = createAsyncThunk('userSlice/getUserById', async (id) => {
    try {
        let response = await axios.get('/user/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteUser = createAsyncThunk('userSlice/deleteUser', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/user/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'User deleted successfully');
        dispatch(getUser());

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('userSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/user/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getUser());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
///** User Password Reset Api

export const updateUserPassword = createAsyncThunk('userSlice/updateUserPassword', async (data) => {
    try {
        let response = await axios.put('/admin/user-reset-password/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'User Password reset successfully!');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const userLogin = createAsyncThunk('userSlice/userLogin', async (id) => {
    try {
        let response = await axios.get('/mock-user/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        data: [],
        options: [],
        selectedUser: {}
    },
    reducers: {
        handleSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },
        removeSelectedUser: (state, action) => {
            state.selectedUser = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getUserById.fulfilled, (state, action) => {
            state.selectedUser = action.payload;
            return;
        });
    }
});

export const { handleSelectedUser, removeSelectedUser } = userSlice.actions;

export default userSlice.reducer;
