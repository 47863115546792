import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getBooth = createAsyncThunk('boothSlice/getBooth', async () => {
    try {
        let response = await axios.get('/booth', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBoothOptions = createAsyncThunk('boothSlice/getBoothOptions', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/booth/options' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBooth = createAsyncThunk('boothSlice/createBooth', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/booth', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Booth added successfully.');
        await dispatch(getBooth());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const updateBooth = createAsyncThunk('boothSlice/updateBooth', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/booth/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Booth updated successfully.');
        dispatch(getBooth());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteBooth = createAsyncThunk('boothSlice/deleteBooth', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/booth/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Booth deleted successfully.');
        await dispatch(getBooth());

        return Promise.resolve(response);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});
export const updateStatus = createAsyncThunk('boothSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/booth/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getBooth());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getBoothById = createAsyncThunk('boothSlice/getBoothById', async ({ id, isDashboard = false }) => {
    try {
        let response = await axios.get('/booth/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: { isDashboard }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBoothByIdWithOutDashboard = createAsyncThunk('boothSlice/getBoothByIdWithOutDashboard', async (id) => {
    try {
        let response = await axios.get('/booth/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const boothSlice = createSlice({
    name: 'boothSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getBooth.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getBoothOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default boothSlice.reducer;
