import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getSurveyType = createAsyncThunk('surveyTypeSlice/getSurveyType', async () => {
    try {
        let response = await axios.get('/survey-type', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSurveyType = createAsyncThunk('surveyTypeSlice/createSurveyType', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/survey-type', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Survey Type added successfully.');
        dispatch(getSurveyType());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSurveyType = createAsyncThunk('surveyTypeSlice/updateSurveyType', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/survey-type/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || ' Survey Type updated successfully.');
        dispatch(getSurveyType());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSurveyType = createAsyncThunk('surveyTypeSlice/deleteSurveyType', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/survey-type/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Survey Type deleted successfully.');
        await dispatch(getSurveyType());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('surveyTypeSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/survey-type/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getSurveyType());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSurveyTypeOptions = createAsyncThunk('electionSlice/getSurveyTypeOptions', async () => {
    try {
        let response = await axios.get('/survey-type/options', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const surveyTypeSlice = createSlice({
    name: 'surveyTypeSlice',
    initialState: {
        data: [],
        surveyTypeOptions: []
    },

    extraReducers: (builder) => {
        builder.addCase(getSurveyType.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSurveyTypeOptions.fulfilled, (state, action) => {
            state.surveyTypeOptions = action?.payload;
            return;
        });
    }
});

export default surveyTypeSlice.reducer;
