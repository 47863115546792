import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getPartyNomination = createAsyncThunk('partyNominationSlice/getPartyNomination', async () => {
    try {
        let response = await axios.get('/election-party-nomination', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createPartyNomination = createAsyncThunk(
    'partyNominationSlice/createPartyNomination',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/election-party-nomination', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Election Party Nomination added successfully.');
            dispatch(getPartyNomination());
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const updatePartyNomination = createAsyncThunk(
    'partyNominationSlice/updatePartyNomination',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/election-party-nomination/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Election Party Nomination updated successfully.');
            dispatch(getPartyNomination());
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const deletePartyNomination = createAsyncThunk('partyNominationSlice/deletePartyNomination', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/election-party-nomination/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Election Party Nomination deleted successfully.');
        await dispatch(getPartyNomination());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('partyNominationSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/election-party-nomination/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Election Party Nomination Status updated successfully !');
        dispatch(getPartyNomination());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const partyNominationSlice = createSlice({
    name: 'partyNominationSlice',
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getPartyNomination.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default partyNominationSlice.reducer;
