import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getDiwaliReportFamily = createAsyncThunk('surveyDiwaliSlice/getDiwaliReportFamily', async (data) => {
    try {
        let response = await axios.get('/voter/member/' + data?.id, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: { surveyId: data?.surveyId },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const surveyDiwaliSlice = createSlice({
    name: 'surveyDiwaliSlice',
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getDiwaliReportFamily.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default surveyDiwaliSlice.reducer;
